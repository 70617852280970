/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Jérémy TO",
  title: "Bonjour, je suis Jérémy TO",
  subTitle: emoji(
    "Étudiant informatique à l'ESIEA et Apprenti Ingénieur au Crédit Agricole Personal Finance et Mobility."
  ),
  resumeLink:
    "", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/to-jeremy",
  linkedin: "https://www.linkedin.com/in/jeremy-to",
  email: "to@et.esiea.fr",
  display: true // Set true to display this section, defaults to false
};

//About Me Section

const aboutMeSection = {
  title: "Qui suis-je ?",
  subTitle: "Je m'appelle TO Jérémy, j'ai 22 ans, et je réside en Île-de-France.",
  aboutMe: [
    "Je suis en 1ère année du cycle ingénieur à l'ESIEA d'Ivry-sur-Seine en apprentissage.",
    "Je suis passionné par le développement de logiciels et d'applications web.",
    "Mon objectif professionnel est de devenir Ingénieur Développeur Full Stack. Mon parcours académique et mon engagement dans le domaine informatique me préparent à relever les défis passionnants du développement informatique."
  ],
  display: true // Set false to hide this section, defaults to true
}

// Skills Section

const skillsSection = {
  title: "Ce que je fais ?",
  subTitle: "Apprenti Ingénieur Full Stack qui a la soif de développer ses compétences !",
  skills: [
    emoji(
      "⚡ Développer des interfaces front-end/utilisateurs pour les applications web et mobiles"
    ),
    emoji("⚡ Développer des fonctionnalités back-end pour les applications web et mobiles"),
    emoji(
      "⚡ Déployer les applications sur des serveurs cloud herbergés par des fournisseurs comme Azure ou AWS"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-git-alt"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "MySQL",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "Azure",
      fontAwesomeClassname: "fab fa-microsoft"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "ESIEA à Ivry-sur-Seine",
      logo: require("./assets/images/logo-esiea.png"),
      subHeader: "Cycle d'ingénieur en informatique",
      duration: "Septembre 2024 - Septembre 2027",
      /*desc: "",
      descBullets: [
        "",
        ""
      ]*/
    },
    {
      schoolName: "Sorbonne Université",
      logo: require("./assets/images/logo-su.png"),
      subHeader: "Master 1 Informatique - Parcours SAR",
      duration: "Septembre 2023 - Juillet 2024",
      //desc: "",
      //descBullets: [""]
    },
    {
      schoolName: "Efrei Paris",
      logo: require("./assets/images/logo-efrei.png"),
      subHeader: "Bachelor 3 Développement et Data en alternance",
      duration: "Septembre 2022 - Juillet 2023",
      desc: "Le diplome du Bachelor m'a formé aux développements d'applications et aux projets en mode agile. J'ai pu acquérir les bases des langages de programmation suivantes : Node.JS, React.JS, Java et Python, ainsi que les bases de données avec MySQL et MongoDB.",
      descBullets: ["Réalisation d'une alternance de neuf mois chez AI Pro Management."]
    },
    {
      schoolName: "IUT de Créteil-Vitry (campus de Vitry)",
      logo: require("./assets/images/logo-upec.png"),
      subHeader: "DUT Réseaux et Télécommunications",
      duration: "Septembre 2020 - Septembre 2022",
      desc: "Le diplômé du DUT spécialité R&T (Réseaux et Télécommunications) acquiert des connaissances en administration des réseaux IP (Internet), en création d’outils et d’applications informatiques pour les réseaux et télécommunications.",
      descBullets: ["Réalisation d'un stage de deux mois et demi chez TERIA - Exagone."]
    },
    {
      schoolName: "Lycée : Jean Macé (Vitry-sur-Seine)",
      logo: require("./assets/images/logo-jm-vitry.jpeg"),
      subHeader: "Baccalauréat STI2D - Option Innovation Technologique et Éco-Conception",
      duration: "Septembre 2019 - Juillet 2020",
      //desc: ""
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Client (Frontend)", //Insert stack or technology you have experience in
      progressPercentage: "50%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Serveur (Backend)",
      progressPercentage: "50%"
    },
    {
      Stack: "Bases de données",
      progressPercentage: "50%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Apprenti Ingénieur d'études et développement",
      company: "Crédit Agricole Personal Finance et Mobility",
      companylogo: require("./assets/images/logo-capfm.jpg"),
      date: "Septembre 2024 - Présent",
      /*desc: "",
      descBullets: [
        "",
        ""
      ]*/
    },
    {
      role: "Apprenti Développeur Full Stack",
      company: "AI Pro Management",
      companylogo: require("./assets/images/logo-aiprm.png"),
      date: "Décembre 2022 - Septembre 2023",
      desc: "Développement d'une web application de gestion de projets pour les professionnels du secteur automobile utilisant l'intelligence artificielle pour la prédiction des paramètres relatifs à un projet"
    },
    {
      role: "Développeur Stagiaire",
      company: "TERIA - Exagone",
      companylogo: require("./assets/images/logo-teria.png"),
      date: "Mai 2022 - Juillet 2022",
      descBullets: [
        "Projet de station GNSS sur Raspberry Pi",
			  "Installation d'un serveur MQTT",
			  "Travaux quotidiens de maintenance du réseau"
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const projects = {
  title: "Projets",
  subtitle: "J'ai travaillé sur divers projets au fil des années et je suis fier des progrès que j'ai accomplis. Collaborer avec d'autres personnes est un excellent moyen d'apprendre et de se développer, et je suis ouvert aux nouvelles idées et aux commentaires.",
  projects: [
    {
      //image: require(""),
      projectName: "Real Life Simulation Game",
      projectDesc: "Mini jeu de simulation de vie réelle réalisé en Java en cours de développement.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/SimulationLife_by_You"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      //image: require(""),
      projectName: "Job Application (client)",
      projectDesc: "Interface React pour l'application web ToDo List des candidatures.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/job-application-frontend"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Job Application (serveur)",
      projectDesc: "Server Node.js pour l'application web ToDo List des candidatures.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/job-application-backend"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Bot discord",
      projectDesc: "Découverte à la création d'un bot Discord en Python.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/Bot_Discord"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Tâches Gourmandes (démo)",
      projectDesc: "Projet d'application web pour la gestion de recettes fait en Bachelor Développement & Data sur mon temps personnel.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "http://172.167.146.12/"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Jeu de rôle d'un donjon en Java",
      projectDesc: "Jeu de rôle dans un donjon avec : cinq salles, trois personnages, trois classes, des équipements et objets, des ennemis par salle et un boss final.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/JDR_java"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Application ToDoList en Java",
      projectDesc: "Petit projet d'une application de ToDoList pour Android fait avec Android Studio.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/todo_list"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Front-end d'une petite application web en React",
      projectDesc: "Développement web frontal se connectant au back-end que j'ai fait en Node.js.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/React"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Back-end d'une petite application web en Node.js",
      projectDesc: "Couche d'accès aux données avec une base de donnée en MongoDB pour un mini site web.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/Nodejs_mongo"
        }
      ]
    },
    {
      //image: require(""),
      projectName: "Site web avec authentification en Node.js",
      projectDesc: "Développement d'un site web fait en Node.js et qui se connecte avec une base de données MySQL.",
      footerLink: [
        {
          name: "Cliquez dessus",
          url: "https://github.com/to-jeremy/Nodejs_mysql"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

/*const achievementSection = {
  title: emoji("Réalisations et Certifications 🏆 "),
  subtitle:
    "Réalisations, certifications que j'ai obtenus !",

  achievementsCards: [
    {
      title: "",
      subtitle:
        "",
      //image: require(""),
      imageAlt: "",
      footerLink: [
        {
          name: "Certification",
          url: ""
        }
      ]
    },
    {
      title: "",
      subtitle:
        "",
      //image: require(""),
      imageAlt: "",
      footerLink: [
        {
          name: "",
          url: ""
        }
      ]
    },

    {
      title: "",
      subtitle: "",
      //image: require(""),
      imageAlt: "",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "",
          url: ""
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};*/

// Blogs Section

/*const blogSection = {
  title: "",
  subtitle:
    "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "",
      title: "",
      description:
        ""
    },
    {
      url: "",
      title: "",
      description:
        ""
    }
  ],
  display: true // Set false to hide this section, defaults to true
};*/

// Talks Sections

/*const talkSection = {
  title: "",
  subtitle: emoji(
    ""
  ),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: true // Set false to hide this section, defaults to true
};*/

// Podcast Section

/*const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "Voici mes podcasts !",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: true // Set false to hide this section, defaults to true
};*/

// Resume Section
const resumeSection = {
  title: "CV",
  subtitle: "Télécharger mon CV pour plus de détails.",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contactez moi ☎️"),
  subtitle:
    "N'hésitez pas à me contacter par mail ou par téléphone.",
  number: "06 18 81 79 59",
  email_address: "to@et.esiea.fr"
};

// Twitter Section

/*const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};*/

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  aboutMeSection,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  projects,
  contactInfo,
  isHireable,
  resumeSection
};