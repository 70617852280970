import React, {useContext} from "react";
import "./AboutMe.scss";
import {aboutMeSection} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function AboutMe() {
  const {isDark} = useContext(StyleContext);
  if (!aboutMeSection.display) {
    return null;
  }
  return (
    <div className={isDark ? "dark-mode main" : "main"} id="a-propos-de-moi">
      <div className="aboutMe-main-div">
        <Fade right duration={1000}>
          <div className="aboutMe-text-div">
            <h1
              className={isDark ? "dark-mode aboutMe-heading" : "aboutMe-heading"}
            >
              {aboutMeSection.title}{" "}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle aboutMe-text-subtitle"
                  : "subTitle aboutMe-text-subtitle"
              }
            >
              {aboutMeSection.subTitle}
            </p>
            <div>
              {aboutMeSection.aboutMe.map((aboutMe, i) => {
                return (
                  <p
                    key={i}
                    className={
                      isDark
                        ? "dark-mode subTitle aboutMe-text"
                        : "subTitle aboutMe-text"
                    }
                  >
                    {aboutMe}
                  </p>
                );
              })}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}